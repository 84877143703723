import React from 'react'
import Nav from '../nav'
import Project from '../project'
import Contact from '../contact'
function ProjectPage() {
  return (
    <>
    <Nav/>
    <Project/>
    <Contact/>
    
    </>
  )
}

export default ProjectPage