import React from 'react'
import CircularProgressBar from './CircularProgressBar'
function Language() {
  return (
    <>
   
    <section className="services section" id="services">
                <h2 className="section__title">LANGUAGES</h2>
                

                <div className="services__container container grid section__border">
              
                       
<CircularProgressBar progress={50}/>
                        
              

                    {/* <div className="services__card">
                   
                        <div className="services__border"></div>
                    </div>

                    <div className="services__card">
                    
                        <div className="services__border"></div>
                    </div> */}

                </div>
            </section></>
  )
}

export default Language