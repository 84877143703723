
import React from 'react';
import Login from './Login';



const Auth = () => {
    return (
      <div className="auth">
       <Login/>
     
      </div>
    );
  };
  
  export default Auth;