import React from 'react'
import Nav from '../nav'
import Server from '../serverc'
import Contact from '../contact'
function ServerPage() {
  return (
    <>
    <Nav/>
    <Server/>
    <Contact/></>
  )
}

export default ServerPage