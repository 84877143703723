import React from 'react'
import Nav from '../nav'
import Contact from '../contact'
import Footer from '../footer'
function ContactPage() {
  return (
    <>
    <Nav/>
    <Contact/>
    <Footer/>
    </>
  )
}

export default ContactPage