import React from 'react'
import Nav from '../nav'
import Skills from '../skills'
import Contact from '../contact'
function SkillsPage() {
  return (
    <>
    <Nav/>
    <Skills/>
    <Contact/>
    
    </>
  )
}

export default SkillsPage