import React from 'react'
import Nav from '../nav'
import QUALIFICATION from '../QUALIFICATION'
import Contact from '../contact'

function QUALIFICATIONPage() {
  return (
    <>
    <Nav/>
    <QUALIFICATION/>
    <Contact/>
    </>
  )
}

export default QUALIFICATIONPage